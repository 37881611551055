<template>
    <section id="career" class="trunk">
        <h2>Job Opennings</h2>
        <h3>Client Acquisition & Account Manager</h3>
        <p>
            AI Directly is a start-up company at the forefront of AI applications. Leveraging our proprietary SaaS products, FirmGPT and TextingGPT, we help clients integrate AI, typically ChatGPT, into their websites, mobile apps, and other software systems. In the second tier of our product line, we offer an AI-powered immigration service platform that assists newcomers with settlement inquiries, job finding, and business planning. For details, please visit our websites at <a href="#">ai-directly.com/career</a> and <a href="https://immigrun.com" target="_blank">immigrun.com</a>.
        </p>
        <p>
            We are looking for a <b>Client Acquisition & Account Manager</b> who holds a burning desire for success, through helping people excel with artificial intelligence.
        </p>
        <h4>Responsibilities:</h4>
        <ul>
            <li>Prospect and engage leads to sell FirmGPT through cold calls, emails, LinkedIn InMail, etc.</li>
            <li>Promote the AI-powered immigration platform to communities of new immigrants, refugees, and international students through outreach efforts like distributing flyers and giving seminars.</li>
            <li>Solicit advertising and coupon distribution services to small businesses through field visits and cold calls.</li>
            <li>Follow up on proposals and close deals with support from our technical team.</li>
            <li>Maintain the accounts you develop and deliver consistent after-sales service.</li>
        </ul>
        <h4>Qualifications:</h4>
        <ul>
            <li>A college diploma or equivalent, preferably in technology or science fields, with the ability to grasp AI concepts at a business level.</li>
            <li>Prior experience in canvassing and cold calling is preferred.</li>
            <li>Excellent communication and interpersonal skills are essential.</li>
            <li>Resilient, determined, and perseverant in the face of challenges and rejection.</li>
            <li>Connections to ethnic communities of newcomers are a significant advantage.</li>
        </ul>
        <p>
            This role is designed for you to grow with us, and your efforts will be rewarded with a competitive commission structure. You will be well supported by our technical team both pre- and after-sales. Training will also be provided to help you understand the products and technology at a business level. 
        </p>
        <p>
            We would love to listen to your story and your ambition. If this opportunity resonates with you, please email your resume to <u>team@ai-directly.com</u>.
        </p>

        <h3 id="pts-1">Part-time Small Business Sales Consultant</h3>
        <p>
            AI Directly is a start-up company at the forefront of AI applications. Leveraging our proprietary SaaS products, FirmGPT and TextingGPT, we help clients integrate AI, typically ChatGPT, into their websites, mobile apps, and other software systems. In the second tier of our product line, we offer an AI-powered immigration service platform that assists newcomers with settlement inquiries, job finding, and business planning. For details, please visit our websites at <a href="#">ai-directly.com/career</a> and <a href="https://immigrun.com" target="_blank">immigrun.com</a>.
        </p>
        <p>
            We are looking for a <b>Part-time Small Business Sales Consultant</b> eager to earn extra income through a side job by helping small businesses acquire new customers from newcomers via our immigration service platform.
        </p>
        <h4>Responsibilities:</h4>
        <ul>
            <li>Solicit advertising and full-cycle coupon management services to small businesses through field visits, networking, and other outreach methods;</li>
            <li>Follow up on proposals, close deals, and maintain the accounts you develop;</li>
            <li>Promote the AI-powered immigration platform to communities of new immigrants, refugees, and international students through outreach efforts like distributing flyers.</li>
        </ul>
        <h4>Qualifications:</h4>
        <ul>
            <li>Prior experience in canvassing and cold calling is preferred;</li>
            <li>Excellent communication and interpersonal skills are essential;</li>
            <li>Resilient, determined, and perseverant in the face of challenges and rejection;</li>
            <li>Connections to ethnic communities of newcomers are a significant advantage.</li>
        </ul>
        <p>
            This commission-based part-time position offers complete flexibility in scheduling, and you will be rewarded with a highly competitive commission rate. You will receive full support from our technical team for both pre- and after-sales. Training will also be provided to help you understand the products and technology at a business level. 
        </p>
        <p>
            If this opportunity resonates with you, please email your resume to team@ai-directly.com. This job description can also be found at ai-directly.com/career.
        </p>
    </section>
</template>

<script>
export default {
    name: 'CareerView',
};
</script>

<style scoped>
h2 {
    margin-top: 16px;
}

#career h3 {
    margin-top: 40px;
    color: rgb(220, 80, 0);
    font-size: larger;
}

u {
    text-decoration: none;
    box-shadow: 0 1px 0 0 gray;
}
</style>