<template>
    <section id="immigrun" class="trunk">
        <pageCtrl v-model:lang="curLang" :page="'/immigrun'" :cloak="cloak" />

        <iframeCompo :key="coid" coid="ImmigRun" :lang="curLang">
            <template #foreword>
                <div v-if="curLang === 'en'">
                    <h2>AI Immigration Consultant</h2>
                    <p>
                        Our GPT-powered immigration consultant possesses a thorough understanding of the laws, regulations, and policies that govern immigration, refugee matters, and studying abroad in Canada, Australia, New Zealand, and the United States. <!--He also has extensive resources for all kinds of services.-->
                    </p>
                    <p>
                        Its powerful <router-link to="/immigrun/cvbuild"><strong>Resume GPT</strong></router-link> helps you screen jobs for your aspirations, enhance your resume for <abbr title="Applicant Tracking System"><b>ATS</b></abbr> filtration, and create and tailor your resume to individual job descriptions.
                    </p>
                    <p>
                        Its erudite <router-link to="/immigrun/bizplan"><strong>BizPlan AI</strong></router-link> mentors you through business planning if you start a business. <!--and crafts the plan. both the business plan and pitch deck slides for you.-->
                    </p>
                    <!--p>
                        All of these services are completely FREE to the public.
                    </p-->
                    <div class="gpt-greet">
                        Hello! Please feel free to share your situation and ask any questions about immigration and schooling. I’m here to help you get started on the right foot and make well-informed decisions for your success.
                    </div>
                </div>
                <div v-if="curLang === 'fr'">
                    <h2>Consultant en Immigration IA</h2>
                    <p>
                        Notre consultant en immigration alimenté par GPT possède une compréhension approfondie et complète des lois, réglementations et politiques régissant <strong>l'immigration</strong>, le statut de <strong>réfugié</strong>, les <strong>études à l'étranger</strong>, et les voyages internationaux au Canada, en Australie, en Nouvelle-Zélande et aux États-Unis.
                    </p>
                    <div class="gpt-greet">
                        Bonjour ! Comment puis-je vous aider aujourd'hui ?
                        <p>
                            Vous pouvez partager votre situation et poser toutes vos questions. Je vous aiderai à bien démarrer et à prendre des décisions éclairées pour votre réussite.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'cn'" class="lang-cn">
                    <h2>“润博士” AI 移民留学顾问</h2>
                    <p>
                        我们的人工智能移民顾问对加拿大、澳大利亚、新西兰和美国的移民和难民法、公民法、以及相关规定和政策有着深入且全面的掌握。<!--他也了解各种相关的服务资源。-->在此, 他将尽心解答您有关<u>移民</u>、<u>留学</u>、以及<u>难民身份</u>的任何问题。此外，他还熟知中国的《民法典》和《公司法》等法律法规，可以帮您对一些具体情况，比如涉及国籍等的各种问题，做法律层面的细致解读。
                    </p>
                    <p>
                        他强大的 <router-link :to="{ path: '/immigrun/cvbuild', query: { lang: curLang } }">简历精灵</router-link> 帮您基于职业抱负筛选工作，通过零散的资料或者中文简历来创建英文简历。针对不同的企业和工作，帮您定向裁剪简历以契合每一份工作要求，并提高简历<u>通过 ATS 筛选</u>的几率。
                    </p>
                    <p>
                        他博学勤勉的 <router-link :to="{ path: '/immigrun/bizplan', query: { lang: curLang } }">生意规划人工智能</router-link> 指导您进行商业规划，同时又为您制作完成商业计划书。
                    </p>
                    <div class="gpt-greet">
                        您好！欢迎咨询任何移民和留学问题！请告知您的详情, 我来帮您深入分析并优选规划，助您顺利实现梦想。
                    </div>
                </div>
                <div v-if="curLang === 'tcn'" class="lang-cn">
                    <h2>AI 移民留學顧問</h2>
                    <p>
                        我們的人工智慧移民顧問對<u>加拿大</u>、<u>澳洲</u>、<u>紐西蘭</u>和<u>美國</u>的移民和難民法、公民法，以及相關規定和政策有著深入且全面的掌握。<!--他也了解各種相關的服務資源。-->在此，他將盡心解答您有關移民、留學、難民身份，以及跨國旅行的任何問題。
                    </p>
                    <div class="gpt-greet">
                        您好！歡迎諮詢任何移民和留學問題！請詳細描述您的情況，我會幫您深入分析並優選規劃，助您順利實現夢想。
                    </div>
                </div>
                <div v-if="curLang === 'kor'">
                    <h2>AI 이민 유학 상담사</h2>
                    <p>
                        저희 인공지능 이민 상담사는 캐나다, 호주, 뉴질랜드, 그리고 미국의 이민 및 난민법, 시민법, 관련 규정 및 정책에 대해 깊고 포괄적인 지식을 가지고 있습니다. 여기서, 이민, 유학, 난민 신분, 그리고 국제 여행에 관한 모든 질문에 성심껏 답변해 드리겠습니다.
                    </p>
                    <div class="gpt-greet">
                        안녕하세요! 이민 및 유학에 관한 모든 질문을 환영합니다! 상황을 자세히 설명해 주시면, 저는 심층 분석과 최적의 계획을 도와드리겠습니다. 꿈을 이루는 데에 성공적으로 나아가실 수 있도록 돕겠습니다.
                    </div>
                </div>
                <div v-if="curLang === 'es'">
                    <h2>Consultor de Inmigración IA</h2>
                    <p>
                        Nuestro consultor de inmigración impulsado por GPT posee un conocimiento profundo y completo de las leyes, regulaciones y políticas que rigen la <strong>inmigración</strong>, el estatus de <strong>refugiado</strong>, los <strong>estudios en el extranjero</strong> y los viajes internacionales en Canadá, Australia, Nueva Zelanda y los Estados Unidos.
                    </p>
                    <div class="gpt-greet">
                        ¡Hola! ¿En qué puedo ayudarte hoy?
                        <p>
                            Puedes compartir tu situación y hacer cualquier pregunta. Te ayudaré a comenzar con el pie derecho y a tomar decisiones informadas para tu éxito.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'pt'">
                    <h2>Consultor de Imigração IA</h2>
                    <p>
                        Nosso consultor de imigração baseado em GPT possui um conhecimento profundo e abrangente das leis, regulamentos e políticas que regem a <strong>imigração</strong>, o status de <strong>refugiado</strong>, os <strong>estudos no exterior</strong> e as viagens internacionais no Canadá, Austrália, Nova Zelândia e Estados Unidos.
                    </p>
                    <div class="gpt-greet">
                        Olá! Como posso te ajudar hoje?
                        <p>
                            Você pode compartilhar sua situação e fazer qualquer pergunta. Vou te ajudar a começar bem e tomar decisões bem-informadas para o seu sucesso.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'far'" class="lang-far">
                    <h2>مشاور هوش مصنوعی برای مهاجرت و تحصیل در خارج</h2>
                    <p>
                        مشاور مهاجرت ما که بر اساس GPT عمل می‌کند، دارای درک کامل و جامع از قوانین، مقررات و سیاست‌های مربوط به <strong>مهاجرت</strong>، وضعیت <strong>پناهندگی</strong>، <strong>تحصیل در خارج</strong> و سفرهای بین‌المللی به کانادا، استرالیا، نیوزیلند و ایالات متحده است.
                    </p>
                    <div class="gpt-greet">
                        سلام! چطور می‌توانم امروز به شما کمک کنم؟
                        <p>
                            می‌توانید وضعیت خود را به اشتراک بگذارید و هر سؤالی که دارید بپرسید. من به شما کمک می‌کنم که کار خود را با موفقیت شروع کنید و تصمیمات آگاهانه‌ای برای موفقیت خود بگیرید.
                        </p>
                    </div>
                </div>
            </template>

            <!-- Add a stamp of 'FREE' on the <iframe> -->
            <!--template #widget> 
                <img id="stamp" src="/img/FREE.png" alt="FREE" />
            </template-->

            <template #afterword>
                <div v-if="curLang === 'en'">
                    <!--p>
                        This AI immigration consultant has a <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad" target="_blank" rel="noopener noreferrer" @click.stop="">clone</a> accessible through OpenAI ChatGPT.
                    </p-->
                    <p>
                        <sup>*</sup> The conversation content will be lost once you close the browser tab unless you check this checkbox before the chat. For your privacy, if you choose to enable this feature, please ensure you're on a private device.
                    </p>
                    <p>
                        For your privacy and data security, please refrain from sharing any personal identification information.
                    </p>
                    <h3>Business Partnership</h3>
                    <p>
                        For businesses serving new immigrants and international students, please click <router-link to="/immigrun/partner">HERE</router-link> to list your services.
                    </p>
                </div>
                <div v-if="curLang === 'fr'">
                    <p>
                        Pour votre confidentialité et la sécurité de vos données, veuillez ne pas partager d'informations d'identification personnelle.
                    </p>
                    <h3>Partenariat Commercial</h3>
                    <p>
                        Pour les entreprises desservant les nouveaux immigrants et les étudiants internationaux, veuillez cliquer <router-link :to="{ path: '/immigrun/partner', query: { lang: curLang } }">ICI</router-link> pour lister vos services.
                    </p>
                </div>
                <div v-if="curLang === 'cn'" class="lang-cn">
                    <!--p>
                        这个人工智能移民顾问还可以通过 OpenAI ChatGPT 上的 <a href="https://chatgpt.com/g/g-5jA5DOoao-yi-min-liu-xue-nan-min-lu-xing-qian-zheng-zhong-guo-min-fa-dian-he-gong-si-fa-deng" target="_blank" rel="noopener noreferrer" @click.stop="">副本</a> 进行访问。
                    </p-->
                    <p>
                        <sup>*</sup> 如果想保存对话内容，请勾选该选项，否则对话内容将在浏览器标签页关闭后丢失。但是请务必在私人设备上勾选此项以防止隐私泄露。
                    </p>
                    <p>
                        为了保护您的隐私和数据安全，请避免提供任何个人真实身份信息。比如可以用“张三”替代真实姓名。
                    </p>
                    <h3>业务伙伴</h3>
                    <p>
                        服务于移民留学人群的上下游公司请按 <router-link :to="{ path: '/immigrun/partner', query: { lang: curLang } }"><u>这里</u></router-link> 发布业务内容。
                    </p>
                </div>
                <div v-if="curLang === 'tcn'" class="lang-cn">
                    <p>
                        為了保護您的隱私和資料安全，請避免提供任何個人的真實身份資訊。您可以使用“張三”來代替真實姓名。
                    </p>
                    <h3>業務夥伴</h3>
                    <p>
                        服務新移民與留學生的上下游企業請按 <router-link :to="{ path: '/immigrun/partner', query: { lang: curLang } }"><u>這裡</u></router-link> 列出您的服務內容。
                    </p>
                </div>
                <div v-if="curLang === 'kor'">
                    <p>
                        고객님의 개인정보와 데이터 보안을 위해, 개인 신원 정보를 제공하지 않도록 주의해 주세요.
                    </p>
                    <h3>비즈니스 파트너</h3>
                    <p>
                        이민자 및 유학생을 위한 서비스를 제공하는 기업은 <router-link :to="{ path: '/immigrun/partner', query: { lang: curLang } }">여기</router-link>를 클릭하여 서비스를 등록해 주세요.
                    </p>
                </div>
                <div v-if="curLang === 'es'">
                    <p>
                        Para proteger su privacidad y seguridad de datos, por favor no comparta ninguna información de identificación personal.
                    </p>
                    <h3>Asociación Comercial</h3>
                    <p>
                        Para las empresas que atienden a nuevos inmigrantes y estudiantes internacionales, haga clic <router-link :to="{ path: '/immigrun/partner', query: { lang: curLang } }">AQUÍ</router-link> para listar sus servicios.
                    </p>
                </div>
                <div v-if="curLang === 'pt'">
                    <p>
                        Para garantir sua privacidade e a segurança de seus dados, por favor, não compartilhe nenhuma informação de identificação pessoal.
                    </p>
                    <h3>Parceria Comercial</h3>
                    <p>
                        Para empresas que atendem novos imigrantes e estudantes internacionais, clique <router-link :to="{ path: '/immigrun/partner', query: { lang: curLang } }">AQUI</router-link> para listar seus serviços.
                    </p>
                </div>
                <div v-if="curLang === 'far'" class="lang-far">
                    <p>
                        برای حفظ حریم خصوصی و امنیت اطلاعات شما، لطفاً از به اشتراک گذاشتن هرگونه اطلاعات شخصی خودداری کنید.
                    </p>
                    <h3>همکاری تجاری</h3>
                    <p>
                        برای کسب‌وکارهایی که به مهاجران جدید و دانشجویان بین‌المللی خدمات ارائه می‌دهند، لطفاً برای فهرست کردن خدمات خود <router-link :to="{ path: '/immigrun/partner', query: { lang: curLang } }">اینجا</router-link> کلیک کنید.
                    </p>
                </div>

                <h3 v-if="curLang === 'en'">Privacy Policy</h3>
                <h3 v-if="curLang === 'fr'">Politique de confidentialité</h3>
                <h3 v-if="curLang === 'cn'" class="lang-cn">隐私政策</h3>
                <h3 v-if="curLang === 'tcn'" class="lang-cn">隱私政策</h3>
                <h3 v-if="curLang === 'kor'">개인정보 처리방침</h3>
                <h3 v-if="curLang === 'es'">Política de privacidad</h3>
                <h3 v-if="curLang === 'pt'">Política de Privacidade</h3>
                <div v-if="curLang === 'far'" class="lang-far">
                    <h3>سیاست حفظ حریم خصوصی</h3>
                </div>
                <ol>
                    <li>
                        Information We Log: Our system logs conversation messages for troubleshooting purposes in case of system errors.
                        Our system may also log technical details such as IP addresses, browser types, and usage statistics to optimize service performance.
                    </li>
                    <li>
                        How We Use Your Data: Data logs are solely used for troubleshooting and debugging technical issues.
                        No human reviews or analyses conversation logs unless a technical issue requires manual debugging.
                        We do not disclose conversation messages to any third party, except for ChatGPT API Platform that handles the conversation. ChatGPT API operates under OpenAI's privacy policies, which AI Directly does not control.
                    </li>
                    <li>
                        User Responsibilities: Users are advised NOT to enter personal identification information (such as names, IDs, or sensitive data) when using our services.
                        We recommend substituting private information with anonymized data to ensure privacy.
                    </li>
                    <li>
                        Legal Compliance: We will only share data if required by law, such as responding to valid legal requests, ensuring compliance with applicable regulations.
                    </li>
                    <li>
                        Policy Updates: We may update this Privacy Policy from time to time. Changes will be posted on this page, and we encourage users to review the policy periodically.
                    </li>
                </ol>

                <h3 v-if="curLang === 'en'">Disclaimer</h3>
                <h3 v-if="curLang === 'fr'">Avertissement</h3>
                <h3 v-if="curLang === 'cn'" class="lang-cn">免责声明</h3>
                <h3 v-if="curLang === 'tcn'" class="lang-cn">免責聲明</h3>
                <h3 v-if="curLang === 'kor'">면책 조항</h3>
                <h3 v-if="curLang === 'es'">Descargo de responsabilidad</h3>
                <h3 v-if="curLang === 'pt'">Aviso Legal</h3>
                <div v-if="curLang === 'far'" class="lang-far">
                    <h3>سلب مسئولیت</h3>
                </div>
                <p>
                    This digital immigration consultant is powered by AI, specifically the GPT model, and has been trained on relevant legal texts, government program materials, and service market data. It provides immigration advice and solutions to the public, free of charge. However, as an AI but a human being, it is not eligible to obtain the licenses and certifications required for lawyers, registered consultants, or licensed advisors in the immigration field. Please exercise caution when considering the advice and solutions offered. AI Directly, the creator of this AI immigration consultant, assumes no responsibility for outcomes resulting from your reliance on its information output.
                </p>
            </template>
        </iframeCompo>
    </section>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import iframeCompo from "../components/iframeCompo.vue";
import pageCtrl from "../components/pageCtrl.vue";

export default {
    name: 'ImmigView',
    components: {
        iframeCompo,
        pageCtrl
    },
    setup() {
        // The language display is pretty complicated
        // 1. Display in languages is controlled by variable curLang and v-if
        // 2. if the language is cn or tcn, the CSS will let the space between characters be wider through the class lang-cn
        // 3. if the language is far, the CSS wil let the font be larger through the class lang-far
        // 4. if the language is cn or tcn, labels and buttons will be shown in Chinese due to variable cnDisp and v-if, while cnDisp is set by @change script in <select>
        const queryParams = new URLSearchParams(window.location.search);
        const curLang = ref(queryParams.get('lang') || 'en');

        // The following is specifically for accommodating immigrun.com
        const page = queryParams.get('page')?.toLowerCase();
        if (['cvbuild', 'bizplan', 'coupon', 'immigform'].includes(page)) {
            useRouter().push({
                path: `/immigrun/${page}`,
                query: { lang: (curLang.value === 'en') ? undefined : curLang.value },
            });
        }
        const cloak = (window.self !== window.top);

        //
        return { curLang, cloak };
    },
}
</script>

<style scoped>
li {
    margin-bottom: 8px;
}

/************************************************ */
/* Language control now is moved to pageCtrl.vue  */
/************************************************ */


/**************** */
/* iframe control */
/**************** */
#stamp {
    position: absolute; 
    top: -18px; 
    right: 48px; 
    z-index: 10; 
    width: 60px; 
    height: 56px;
}

@media screen and (max-width: 1023px) {
    #stamp {
        top: -14px;
        width: 52px; 
        height: 48px;
    }
}
</style>
