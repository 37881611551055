<template>
    <section id="cv-builder" class="trunk">
        <pageCtrl v-model:lang="curLang" :page="'/immigrun/cvbuild'" :cloak="cloak" />

        <iframeCompo :key="coid" coid="CVbuild" email_subject="Your resume built by AI-Directly Resume GPT">
            <template #foreword>
                <h2>Resume GPT</h2>
                <p>
                    What does this AI do for you?
                </p>
                <ul>
                    <li>Retrieves job postings from websites, analyzes, and <b>screens jobs</b> that match your aspirations, saving you tons of precious time off those not fitting you!</li>
                    <li>Crafts a brand-new resume and cover letter through GPT-powered conversations<sup>†</sup>.</li>
                    <li>Recommends keywords to add, tailors, and refines your resume to align with each job description, boosting your chances of passing 
                        <iframeTooltip :tooltip_q="'What is ATS filter?'" :tooltip_a="'An ATS (Applicant Tracking System) filter is a software used by employers to streamline their recruitment process. It automates the initial stage of resume screening by sorting through applications and identifying those that match the job description based on specific keywords, skills, experience, and qualifications. Resumes that do not pass the ATS filter may not reach human recruiters for further review. Ensuring your resume is optimized with relevant keywords and formatted correctly can improve your chances of passing through an ATS filter.'">
                            <template #trigger>
                                <strong>ATS filters</strong>,
                            </template>
                        </iframeTooltip>
                        and giving you an edge for any opportunity.</li>
                </ul>
                <div class="gpt-greet">
                    Let's work on that in two steps:
                    <ol>
                        <li>
                            Enter your profile information, including your skills, experience, education, certifications, etc., in any language.
                        </li>
                        <li>
                            Paste job posting links one by one, and I will analyze each website<sup>‡</sup> to provide a job screening assessment or craft a tailored resume.
                        </li>
                    </ol>
                </div>
            </template>

            <template #afterword>
                <p>
                    Once you're satisfied with the resume, you can request to have it sent to your email.
                </p>
                <p>Note:</p>
                <ol class="notes">
                    <li><sup>†</sup> For optimal results, Resume GPT is best used on laptops or desktops with wider screens to render file pages.</li>
                    <li><sup>‡</sup> Some websites, like LinkedIn and Monster, have anti-bot measures in place, which may prevent our AI from accessing them effectively.</li>
                    <li><sup>*</sup> The conversation content will be lost once you close the browser tab unless you check this checkbox before the chat. For your privacy, if you choose to enable this feature, please ensure you're on a private device.</li>
                </ol>
                <!--p>
                    Please remain on this page while we work on your resume. Navigating away will drop the conversation content.
                </p-->
            </template>
        </iframeCompo>
    </section>
</template>

<script>
import { ref } from 'vue';
import iframeCompo from "../components/iframeCompo.vue";
import iframeTooltip from "../components/iframeTooltip.vue";
import pageCtrl from "../components/pageCtrl.vue";

export default {
    name: 'CVbuildView',
    components: {
        iframeCompo,
        iframeTooltip,
        pageCtrl
    },
    setup() {
        // initialize the display language and whether the page be on post
        // The language display is pretty complicated
        // 1. Display in languages is controlled by variable curLang and v-if
        // 2. if the language is cn or tcn, the CSS will let the space between characters be wider through the class lang-cn
        // 3. if the language is far, the CSS wil let the font be larger through the class lang-far
        // 4. if the language is cn or tcn, labels and buttons will be shown in Chinese due to variable cnDisp and v-if, while cnDisp is set by @change script in <select>
        const queryParams = new URLSearchParams(window.location.search);
        const curLang = ref(queryParams.get('lang') || 'en');

        // The following is specifically for accommodating immigrun.com
        const cloak = (window.self !== window.top);

        //
        return { curLang, cloak };
    }
}
</script>

<style scoped>
.notes {
    padding-left: 0;
    list-style-type: none;
}

li {
    margin-bottom: 6px;
}

.notes li {
    position: relative;
    padding-left: 24px;
    text-indent: -18px;
}

li sup {
    margin-right: 6px;
}
</style>