<template>
    <section id="web-synthesizer" class="trunk">
        <iframeCompo :key="serv_name" :coid="serv_name">
            <template #foreword>
                <h2>Website Synthesizer</h2>
                <p>
                    Please choose a function from the dropdown list.
                </p>
                <select v-model="serv_name">
                    <option value="ComProfile">Company Profile</option>
                    <option value="ImmigNews">Immigration News, Non-Indexed</option>
                    <option value="WebSynth">General Web Synthesis</option>
                </select>
                <p>
                    Please paste the website link into the input box below. This function synthesizes the website and 
                    creates a comprehensive overview for copy-n-paste.
                </p>
            </template>
        </iframeCompo>
    </section>
</template>

<script setup>
import { ref } from "vue";
const serv_name = ref('WebSynth');
</script>


<script>
import iframeCompo from "../components/iframeCompo.vue";

export default {
    name: 'WebsynthView',
    components: {
        iframeCompo,
    },
}
</script>

<style scoped>
</style>